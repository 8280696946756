/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { startCase } from 'lodash'
import { Wrapper, H, Button, CrossIcon } from '@farewill/ui'
import { BORDER, COLOR, GTR } from '@farewill/ui/tokens'
import { useAutoFocus } from '../../../../client/hooks/useAutoFocus'

const StyledHeader = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px ${COLOR.GREY.LIGHT};
  border-radius: ${BORDER.RADIUS.M} ${BORDER.RADIUS.M} 0 0;
`

const StyledTitle = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0 ${GTR.S};
`

const StyledModal = styled.section`
  --modal-padding: ${GTR.M};
  background: var(--modal-content-background, ${COLOR.BACKGROUND.FOG});
  border-radius: ${BORDER.RADIUS.M};
  max-width: 100%;
  width: var(--modal-content-max-width, 500px);
`

const Modal = ({
  className,
  title,
  children,
  handleClose,
  headerIcon,
  hideHeader = false,
  onClose,
  restoreFocusOnClose = true,
}) => {
  // TODO: Use `useId` hook for titleId when we upgrade to React 18 to avoid
  // the need for all this…
  const stripSpacesAndSpecialChars = (str) => str.replace(/[^a-zA-Z0-9]/g, '')
  const sanitisedTitle = stripSpacesAndSpecialChars(startCase(title))
  const titleId = `Modal-${sanitisedTitle}-Label`

  const modalRef = useRef()
  const previouslyFocusedElement = useRef(document.activeElement).current

  useAutoFocus(modalRef)

  // We need to use a ref to store the latest version of `restoreFocusOnClose`
  // so that the cleanup function can access the latest value (and not a stale
  // value from the closure).
  const restoreFocusRef = useRef(restoreFocusOnClose)
  useEffect(() => {
    restoreFocusRef.current = restoreFocusOnClose
  }, [restoreFocusOnClose])

  useEffect(() => {
    return () => {
      if (restoreFocusRef.current) {
        previouslyFocusedElement.focus()
      }
      if (typeof onClose === 'function') {
        onClose({ restoreFocusOnClose: restoreFocusRef.current })
      }
    }
  }, [onClose, previouslyFocusedElement])

  return (
    <StyledModal
      ref={modalRef}
      className={className}
      role="dialog"
      {...(!hideHeader
        ? { 'aria-labelledby': titleId }
        : { 'aria-label': title })}
    >
      {!hideHeader && (
        <StyledHeader background={COLOR.WHITE} padding="M" tag="header">
          <StyledTitle>
            {headerIcon && headerIcon}
            <H id={titleId} margin={[0, GTR.XXS, 0, 0]} size="XS">
              {title}
            </H>
          </StyledTitle>
          <Button aria-label="Close dialog" type="button" onClick={handleClose}>
            <CrossIcon color={COLOR.BLACK} />
          </Button>
        </StyledHeader>
      )}
      <Wrapper padding="var(--modal-padding)">{children}</Wrapper>
    </StyledModal>
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  headerIcon: PropTypes.node,
  hideHeader: PropTypes.bool,
  onClose: PropTypes.func,
  restoreFocusOnClose: PropTypes.bool,
}

Modal.defaultProps = {
  className: null,
}

export default Modal
