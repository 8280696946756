export const trackInGA4 = (command, ...commandParams) => {
  // For tracking events, augment the event params with the application name so
  // that we can more easily tell the source of the event when looking in GA.
  if (command === 'event') {
    const eventName = commandParams[0]
    const eventParams = commandParams[1] || {}
    eventParams.app = 'wills'
    commandParams = [eventName, eventParams]
  }

  /*
  We usually read the config from the Redux state but we don't have
  access to it here. So instead of passing it through each time we
  use the helper, we are reading it from `window.INITIAL_STATE`. The
  `RELEASE_ENV` never changes at runtime so this is safe to do.
  */
  if (window.INITIAL_STATE.config.RELEASE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.debug(`[GTAG] ${command}`, ...commandParams)
  }
  window.gtag?.(command, ...commandParams)
}

/**
 * DNT: do not track
 * This is a (deprecated) API that allows users to express a preference to not be tracked.
 * It can usually be controlled via browser preferences in supported browsers.
 * See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/doNotTrack
 * @todo this is a copy of the same functions from the farewill.com repo. We hope to at some point
 * break this out into a shared package.
 * @returns boolean
 */
export const hasDNTEnabled = () => window.navigator.doNotTrack === '1'

export const getUrlWithParams = (url, utmParameters) => {
  const urlObj = new URL(url)
  const pathname = urlObj.pathname.replace(/\/+$/, '')
  const params = new URLSearchParams(urlObj.search)

  Object.entries(utmParameters).forEach(([key, value]) => {
    params.append(key, value)
  })

  return `${urlObj.origin}${pathname}?${params}`
}
